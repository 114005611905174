import React from "react";

import IguMap from "./_IguMap";
import useFetch from "../../common/hooks/useFetch";
import IguBackground from "./_IguBackground";
import useGetBrasao from "../../common/hooks/useGetBrasao";

const Igu = ({ cityID, cityData }) => {
    // load data -----------------------------------------------------------
    const { data: projInfoData, loading: projInfoDataLoading } = useFetch(
        `/igu/projInfo/${cityID}`
    );
    const { data: interseccaoData, loading: interseccaoDataLoading } = useFetch(
        `/igu/interseccoes/${cityID}`
    );
    const { data: viabilidadeSem, loading: viabilidadeSemLoading } = useFetch(
        `/igu/viabilidadeSem/${cityID}`
    );
    const { data: contagemData, loading: contagemDataLoading } = useFetch(
        `/igu/contagens/${cityID}`
    );
    const { data: projSubData, loading: projSubLoading } = useFetch(
        `/igu/projSubtrecho/${cityID}`
    );
    const { data: areasSimData, loading: areasSimLoading } = useFetch(
        `/igu/areasSimulacao/${cityID}`
    );
    const { data: projIntData, loading: projIntLoading } = useFetch(
        `/igu/projInterligacao/${cityID}`
    );
    const { data: rotasCorredorData, loading: rotasLoading } = useFetch(
        `/igu/rotasCorredor/${cityID}`
    );
    const { data: coordenacaoData, loading: coordLoading } = useFetch(
        `/igu/coordenacao/${cityID}`
    );
    const { data: redeSemaforicaData, loading: redeSemLoading } = useFetch(
        `/igu/redeSemaforica/${cityID}`
    );
    const { data: subRedeSemaforicaData, loading: subRedeSemLoading } =
        useFetch(`/igu/subredeSemaforica/${cityID}`);
    const { data: rotasDesData, loading: rotasDesLoading } = useFetch(
        `/igu/rotasDesempenho/${cityID}`
    );
    const { data: linhasBusData, loading: linhasBusLoading } = useFetch(
        `/igu/linhasOnibus/${cityID}`
    );
    const { data: limMunData, loading: limMunLoading } = useFetch(
        `/igu/limiteMunicipio/${cityID}`
    );
    const { data: zoneamentoData, loading: zoneamentoLoading } = useFetch(
        `/od/zoneamento/${cityID}`
    );
    const { data: matrizODData, loading: matrizODDataLoading } = useFetch(
        `/od/origensedestinos/${cityData.name}`
    );
    const { data: brasaoData, loading: brasaoLoading } = useGetBrasao(cityID);
    const { data: pontosOnibus, loading: pontosOnibusLoading } = useFetch(
        `/igu/pontosOnibus/${cityID}`
    );
    const { data: topografia, loading: topografiaLoading } = useFetch(
        `/igu/topografia/${cityID}`
    );

    const dataLoading =
        limMunLoading ||
        projInfoDataLoading ||
        interseccaoDataLoading ||
        projSubLoading ||
        projIntLoading ||
        rotasLoading ||
        coordLoading ||
        redeSemLoading ||
        subRedeSemLoading ||
        brasaoLoading ||
        rotasDesLoading ||
        linhasBusLoading ||
        zoneamentoLoading ||
        contagemDataLoading ||
        matrizODDataLoading ||
        areasSimLoading ||
        viabilidadeSemLoading ||
        pontosOnibusLoading ||
        topografiaLoading;

    // early return -------------------------------------------------------
    if (dataLoading) return <IguBackground />;

    // if (
    //     projIntData.length === 0 && projSubData.length === 0 &&
    //     rotasCorredorData.length === 0 && coordenacaoData.length === 0 &&
    //     redeSemaforicaData.length === 0 && subRedeSemaforicaData.length === 0 &&
    //     interseccaoData.length === 0 && limMunData.length === 0 && rotasDesData.length === 0 &&
    //     linhasBusData.length === 0 && matrizODOrigDest.length === 0 &&
    //     matrizODPagamentos.length === 0 && zoneamentoData.length === 0
    // ) return null
    // ---------------------------------------------------------------------

    // brasao
    let brasaoUrl;
    if (brasaoData) {
        brasaoUrl = brasaoData;
    }

    // informacoes projeto
    let projData = [];
    if (projInfoData.length > 0) {
        projData = projInfoData;
    }

    // Point layer (intSem, intNoSem, intMap, contagem, OD)
    let intSemData = [];
    let intNoSemData = [];
    let intMapData = [];
    let contagemVeicData = [];
    let contagemODData = [];
    let contODData = [];
    let pontosOnibusData = [];
    if (interseccaoData.length > 0) {
        intSemData = interseccaoData[0].features.filter(
            (e) =>
                e.properties.OINTERSECAOSITUACAO !== undefined &&
                !e.properties.OINTERSECAOSITUACAO.toLowerCase().includes(
                    "escopo"
                ) &&
                !e.properties.OINTERSECAOSITUACAO.toLowerCase().includes(
                    "naosemaforizada"
                ) &&
                e.properties.BLOCKNAME !== "IGU_Rotatoria"
        );
        intNoSemData = interseccaoData[0].features.filter(
            (e) =>
                e.properties.OINTERSECAOSITUACAO !== undefined &&
                !e.properties.OINTERSECAOSITUACAO.toLowerCase().includes(
                    "escopo"
                ) &&
                e.properties.OINTERSECAOSITUACAO.toLowerCase().includes(
                    "naosemaforizada"
                ) &&
                e.properties.BLOCKNAME !== "IGU_Rotatoria"
        );
        intMapData = interseccaoData[0].features.filter(
            (e) =>
                e.properties.OINTERSECAOSITUACAO !== undefined &&
                e.properties.OINTERSECAOSITUACAO.toLowerCase().includes(
                    "escopo"
                ) &&
                e.properties.BLOCKNAME !== "IGU_Rotatoria"
        );
        // apenas IGUs antigos (RP e Atibaia)
        if (contagemData.length === 0) {
            contagemVeicData = interseccaoData[0].features.filter(
                (e) =>
                    e.properties.OCONTAGEM.toLowerCase() === "sim" &&
                    e.properties.BLOCKNAME !== "IGU_Rotatoria"
            );
            contODData = interseccaoData[0].features.filter(
                (e) =>
                    e.properties.OCONTAGEM.toLowerCase() === "sim" &&
                    e.properties.BLOCKNAME === "IGU_Rotatoria"
            );
        }
    }
    if (contagemData.length > 0) {
        contagemVeicData = contagemData[0].features.filter(
            (e) => e.properties.OCONTAGEM.toLowerCase() === "sim"
        );
        contagemODData = contagemData[0].features.filter(
            (e) => e.properties.OCONTAGEMOD.toLowerCase() === "sim"
        );
    }
    if (pontosOnibus.length > 0) {
        pontosOnibusData = pontosOnibus[0].features;
    }
    let viabilidadeSemData = [];
    if (viabilidadeSem.length > 0) {
        viabilidadeSemData = viabilidadeSem[0].features;
    }

    // proj interligacao
    let projInterligacaoData = [];
    if (projIntData.length > 0) {
        projInterligacaoData = projIntData[0].features;
    }

    // proj subtrecho
    let projSubtrechoData = [];
    if (projSubData.length > 0) {
        projSubtrechoData = projSubData[0].features;
    }

    // area simulacao
    let areasSimulacaoData = [];
    if (areasSimData.length > 0) {
        areasSimulacaoData = areasSimData[0].features;
    }

    // rotas corredor
    let rotasData = [];
    if (rotasCorredorData.length > 0) {
        rotasData = rotasCorredorData[0].features;
    }

    // coordenação
    let coordData = [];
    if (coordenacaoData.length > 0) {
        coordData = coordenacaoData[0].features;
    }

    // rede semaforica
    let redeSemData = [];
    if (redeSemaforicaData.length > 0) {
        redeSemData = redeSemaforicaData[0].features;
    }

    // subrede semaforica
    let subRedeSemData = [];
    if (subRedeSemaforicaData.length > 0) {
        subRedeSemData = subRedeSemaforicaData[0].features;
    }

    // limite municipio
    let limiteMunicipioData = [];
    if (limMunData.length > 0) {
        limiteMunicipioData = limMunData[0].geometries;
    }

    // rotas desempenho
    let rotasDesempenhoData = [];
    if (rotasDesData.length > 0) {
        rotasDesempenhoData = rotasDesData[0].features;
    }

    // linhas de ônibus
    let linhasOnibusData = [];
    if (linhasBusData.length > 0) {
        linhasOnibusData = linhasBusData[0].features;
    }

    // topografia
    let topografiaData = [];
    if (topografia.length > 0) {
        topografiaData = topografia[0].features;
    }

    // console.log(limMunLoading, projInfoDataLoading, interseccaoDataLoading, projSubLoading,
    //     projIntLoading, rotasLoading, coordLoading, redeSemLoading, subRedeSemLoading)

    // console.log(
    //     projIntData.length, projSubData.length,
    //     rotasCorredorData.length, coordenacaoData.length,
    //     redeSemaforicaData.length, subRedeSemaforicaData.length,
    //     interseccaoData.length, limMunData.length,   )

    return (
        <>
            <IguMap
                projInfo={projData}
                cidade={cityData}
                loading={dataLoading}
                brasaoUrl={brasaoUrl}
                layerData={{
                    intSemData,
                    intNoSemData,
                    intMapData,
                    viabilidadeSemData,
                    contagemVeicData,
                    contagemODData,
                    contODData,
                    projInterligacaoData,
                    projSubtrechoData,
                    areasSimulacaoData,
                    rotasData,
                    coordData,
                    redeSemData,
                    subRedeSemData,
                    limiteMunicipioData,
                    rotasDesempenhoData,
                    linhasOnibusData,
                    zoneamentoData,
                    matrizODData,
                    pontosOnibusData,
                    topografiaData,
                }}
            ></IguMap>
        </>
    );
};

export default Igu;
