import React, { useState } from 'react'
import Navbar from 'react-bootstrap/Navbar';

import {
    BsFillHouseFill, BsArrowsFullscreen, BsMapFill, BsCardText, 
    BsFillPatchQuestionFill, BsFillBinocularsFill,
    BsInfoCircleFill, BsFillInfoSquareFill,
} from 'react-icons/bs'

import IguToolbarB1 from './_IguToolbarB1';
import IguToolbarB2 from './_IguToolbarB2';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ToolbarButtonsG2 from './_IguToolbarButtonsG2';
import ModalInfoGeral from '../../mainMap/components/_IguInfoGeralModal';
import ModalInfoProj from '../../mainMap/components/_IguInfoProjModal';

const IguToolbar = (props) => {

    const b1GroupTools = props.b1GroupTools;

    // dropdown ajuda
    const ajudaCategories = ["Ajuda", "Sobre o Projeto"];
    const ajudaCategoriesIcons = [<BsInfoCircleFill />, <BsFillInfoSquareFill />];
    const [showAjudaGeralModal, setShowAjudaGeralModal] = useState(false);
    const [showAjudaProjModal, setShowAjudaProjModal] = useState(false);
    const ajudaGeralOnClick = () => setShowAjudaGeralModal(true);
    const ajudaProjOnClick = () => setShowAjudaProjModal(true);
    const infoContrato = props.projInfo.length > 0 ? props.projInfo[0].projInfo : []
    const ajudaOnClick = [ajudaGeralOnClick, ajudaProjOnClick]

    // dropdown rotas

    // dropdown projetos


    return (
        <>
            <ModalInfoGeral 
                show={showAjudaGeralModal} 
                handleClose={() => setShowAjudaGeralModal(false)}
            />
            <ModalInfoProj 
                show={showAjudaProjModal} 
                contrato={infoContrato} 
                cidade={props.cidade} 
                handleClose={() => setShowAjudaProjModal(false)}
            />
            < Navbar fixed="bottom" expand="md" className='toolbar-nav'>
                <div className='igu-toolbar-container'>
                    {/* icone IGU */}
                    <img src="/img/iguIconeIGU.svg" width="40" height="40" className="toolbar-igu-icon" alt="IGU" />

                    {/* Igu toolbar botoes tipo 1 (B1) */}
                    <ButtonGroup aria-label="b1" className='igu-toolbar-group1'>
                        <IguToolbarB1 
                            onClick={() => { b1GroupTools.setHomeView() }} 
                            icon={<BsFillHouseFill />} 
                            btnDescription="Início"
                        ></IguToolbarB1>
                        <IguToolbarB1 
                            onClick={() => { b1GroupTools.setExtZoom() }} 
                            icon={<BsArrowsFullscreen />} 
                            btnDescription="Zoom Extendido"
                        ></IguToolbarB1>
                        <IguToolbarB1 
                            onClick={() => { b1GroupTools.switchTiles() }} 
                            icon={<BsMapFill />} 
                            btnDescription="Trocar base"
                        ></IguToolbarB1>
                        <IguToolbarB1 
                            onClick={() => { b1GroupTools.toggleLegend() }}
                            icon={<BsCardText />} 
                            btnDescription="Legenda"/>
                        <IguToolbarB1 
                            onClick={() => { b1GroupTools.setSearchModalVisible(true) }}
                            icon={<BsFillBinocularsFill />} 
                            btnDescription="Buscar" 
                        />
                    </ButtonGroup>

                    {/* Igu toolbar g2 */}
                    <ToolbarButtonsG2 
                        panes={props.panes}
                        layerVisibility={props.layerVisibility}
                        quantities={props.quantities}
                        b2GroupTools={props.b2GroupTools}
                    />

                    {/* <!-- Ajuda --> */}
                    <ButtonGroup className='igu-toolbar-group3'>
                        <IguToolbarB2
                            icon={<BsFillPatchQuestionFill />}
                            description="Ajuda"
                            categories={ajudaCategories}
                            categoriesIcons={ajudaCategoriesIcons}
                            onClick={ajudaOnClick}
                            quantities={[777, 777]}
                            ehAjuda={true}
                        />
                    </ButtonGroup>
                </div>
            </Navbar >
        </>
    )
};

export default IguToolbar;