const hasPermission = (userRole, requiredRole) => {
    if        (requiredRole === 'admin') {
        if (userRole !== 'admin') return false;
    } else if (requiredRole === 'editor') {
        if (userRole === 'colab' || userRole === 'user') return false;
    } else if (requiredRole === 'colab') {
        if (userRole === 'user') return false;
    } 
    return true
};

export default hasPermission;