const projectTypes = {
    "A05": "Planta de Situação Geral",
    "C01": "Pontes e Viadutos",
    "C04": "Passarelas",
    "C06": "Estruturas de Concreto - Estruturas de Edificações",
    "E01": "Sistemas Elétricos - Redes de alimentação e distribuição, unifilares e polifilares",
    "E02": "Sistemas Elétricos - Infraestrutura para instalações (eletrodutos, caixas e outros)",
    "E04": "Sistemas Elétricos - Instalações de BT, proteção e aterramento",
    "E21": "Instalação Elétrica p/ Sistemas Semafóricos",
    "E22": "Projeto Executivo de Instalação Elétrica e de Sinalização Semafórica",
    "E23": "Rede de Comunicações p/ Sistemas Semafóricos",
    "E24": "Sistemas Elétricos - Iluminação Pública e Interna com análise luminotécnica",
    "E25": "Sistemas Elétricos - Iluminação Pública e Externa com análise luminotécnica",
    "E26": "Sistemas Elétricos - Sistemas de Proteção às Descargas Atmosféricas",
    "E27": "Sinalização Semafórica e de Instalação Elétrica p/ Sistemas Semafóricos e de Câmera do Sistema",
    "E28": "Instalação Elétrica p/ Sistemas de Detecção Veicular com Laço Indutivo",
    "E29": "Instalação Elétrica p/ Sistemas de Detecção Veicular com Laço Virtual (através de Câmeras)",
    "F02": "Geometria Viária - Traçado em planta",
    "F03": "Geometria Viária - Traçado em perfil",
    "F21": "Geometria Viária (Traçado em planta) e Acessibilidade",
    "F22": "Geometria Viária (Traçado em planta) e Pavimentação (Planta de distribuição)",
    "F23": "Acessibilidade Universal",
    "F24": "Acessibilidade Universal e Mobiliário Urbano",
    "G01": "Mapeamento Geológico e localização de sondagens",
    "H02": "Hidráulica - Instalações Hidrosanitárias",
    "H04": "Drenagem de vias",
    "I01": "Cadastro unificado de interferências",
    "J01": "Pesquisa de Tráfego Motorizado",
    "J19": "Classificação e Hierarquização do sistema viário",
    "J21": "Contagem Veicular Classificada - PAIT",
    "J22": "Contagem de Pedestres",
    "J23": "Proposição (ou Proposta) de Circulação Viária",
    "J24": "Reconfiguração Viária de Tráfego, Circulação, Estacionamento, Sinalização e Geometria",
    "J25": "Avaliação (ou Auditoria) de Segurança Viária e Sinalização",
    "J26": "Animação Gráfica de Fluxo Veicular em Situação de Circulação Viária",
    "J27": "Animação Gráfica de Fluxo Pedestre em Situação de Circulação Viária",
    "J28": "Animação Gráfica de Fluxo Pedestre e Veicular em Situação de Circulação Viária",
    "J29": "Estimativa de Massa de Fluxo Veicular",
    "J30": "Pesquisa de Velocidade e Retardamento - “Antes”",
    "J31": "Pesquisa de Velocidade e Retardamento - “Depois”",
    "J32": "Pesquisa de Velocidade e Retardamento - “Antes/Depois”",
    "J33": "Relatório de Viabilidade de Implantação de Sinalização Semafórica em Interseção",
    "J34": "Relatório de Viabilidade de Implantação de Sinalização Semafórica em Rotatória",
    "J35": "Relatório de Viabilidade de Implantação de Sinalização Semafórica em Travessia de Pedestre",
    "J36": "Nível de Serviço em Interseção Semaforizada",
    "J37": "Nível de Serviço em Interseção não Semaforizada",
    "J38": "Estudo de Impacto de Vizinhança (E.I.V.)",
    "J39": "Relatório de Impacto no Trânsito (R.I.T.)",
    "J40": "Contagem Veicular Classificada e Volume de Pedestres (registro de movimentos em interseção viária",
    "J41": "Avaliação do Tempo de Viagem por passagem em interseção nas diferentes situações de circulação viária",
    "J42": "Diagrama das Bandas de Coordenação em rota de circulação em via de sentido único (Onda Verde)",
    "J43": "Diagrama das Bandas de Coordenação em rota de circulação em via de sentido duplo (Onda Verde prioridade no sentido)",
    "J44": "Contagem Veicular Classificada e Volume de Bicicletas",
    "J45": "Contagem Veicular Classificada e Volume de Bicicletas e Pedestres",
    "J46": "Contagem Veicular Classificada em seção viária",
    "J47": "Contagem Veicular Classificada e Volume de Bicicletas em seção viária",
    "J48": "Contagem Veicular Classificada em tipo de veículo e número de eixos em seção viária",
    "J49": "Contagem Veicular de tipo de veículo específico com registro de velocidade em seção viária",
    "J50": "Pesquisa de Identificação de Linhas em Parada de Ônibus",
    "J51": "Pesquisa de Embarque e Desembarque de passageiros em Parada de Ônibus com Identificação de Linhas",
    "J52": "Pesquisa de Ocupação Visual de Passageiros das Linhas de Ônibus em ponto/local do Sistema Viário",
    "J53": "Contagem de Veiculo tipo bicicleta e Volume de Pedestres (registro de movimentos em interseção viária)",
    "J54": "Contagem de Veiculo tipo bicicleta e Volume de Pedestres em seção viária",
    "J62": "Banda de Coordenação",
    "J63": "Contagem de Origem e Destino Veicular",
    "J67": "Contagem Veicular Classificada - PMU",
    "K01": "Arquitetura - Paisagismo",
    "K02": "Arquitetura - Plantas, Cortes e Vistas",
    "K04": "Arquitetura - Comunicação Visual",
    "K05": "Arquitetura - Mobiliário e leiaute",
    "K07": "Arquitetura - (Planejamentos) Urbanístico",
    "K08": "Arquitetura - Urbanização e Ajardinagem",
    "K17": "Urbanístico para Infraestrutura de Apoio à Acessibilidade (Demolição)",
    "K18": "Urbanístico para Infraestrutura de Apoio à Acessibilidade (Construção)",
    "K21": "Arquitetura - Perspectivas Tridimensionais",
    "K22": "Obra Civil para redes de dutos compartilhados entre Sistemas  Semafóricos, de Detecção Veicular Indutiva, Rede de Comunicações e CFTV de Tráfego",
    "K23": "Obra Civil para redes de dutos compartilhados entre Sistemas  Semafóricos, de Detecção Veicular Virtual, Rede de Comunicações e CFTV de Tráfego",
    "K23__1": "Arquitetura - Reavaliação de Paradas de Ônibus com proposição para melhorias",
    "L01": "Sinalização Horizontal",
    "L02": "Sinalização Vertical",
    "L03": "Dispositivos de Segurança Viária",
    "L07": "Sinalização Vertical e Horizontal",
    "L12": "Sinalização Semafórica",
    "L13": "Projeto de Orientação de Tráfego",
    "L21": "Sinalização Vertical, Horizontal e Semafórica para veículos motorizados e não-motorizados (ou cicláveis)",
    "M02": "Estruturas metálicas de edificações",
    "O20": "Programação Semafórica",
    "O21": "Croqui de Programação Semafórica",
    "O22": "Programação Semafórica em arquivo para transferência em equipamento Sice (Prado)",
    "P02": "Pavimentação - Planta de distribuição de pavimento",
    "Q02": "Terraplenagem - Planta de distribuição de volumes",
    "T01": "Levantamento Topográfico - Planialtimétrico (Sistema convencional)",
    "T21": "Reconstituição aerofotogramétrica do viário e da sinalização a partir de aerofotos de fonte GoogleEarthPro",
    "T22": "Levantamento Topográfico - Planialtimétrico através sistema laser por nuvem de pontos",
    "X01": "Detecção e Combate à Incêndio",
    "X0?": "Segurança",
    "X0?__1": "Informações",
}

export {projectTypes};