import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { FaUsers, FaMapMarkedAlt, FaFileImport, FaFileAlt } from 'react-icons/fa';

import { UserContext } from "../common/contexts/user-context";
import hasPermission from '../common/utils/acessControl';

const IndexPage = () => {
    const { role, cityID } = useContext(UserContext);
    const navigate = useNavigate();

    const goToCitiesPage = () => {
        navigate('/admin/cities')
    };
    const goToUsersPage = () => {
        navigate('/admin/users')
    };
    const goToLogsPage = () => {
        navigate('/admin/logs')
    };
    const goToIguPage = () => {
        navigate('/')
    };

    let title;
    let editCityText;
    switch (role) {
        case 'admin':
            title = 'Área do Administrador'
            editCityText = 'Ver/Editar Cidades'
            break;
        case 'editor':
            title = 'Área do Editor'
            editCityText = 'Ver/Editar Cidades'
            break;
        case 'colab':
            title = 'Área do Colaborador'
            editCityText = 'Ver Cidades'
            break;
        default:
            title = 'Painel de Controle'
            break;
    }

    return (
        <div className="indexPage-main-container">
            <div className="indexPage-title">
                {`${title}`}
            </div>
            <div className="indexPage-sec-container">
                <div className="indexPage-inner-container">
                    {role === 'user' &&
                        <div className="indexPage-icons-boundingBox">
                            <a href="#/" onClick={goToIguPage}>
                                <div className="indexPage-main-icon">
                                    <img width="88" height="88"
                                        src="/img/Logo IGU-loginpage.svg"
                                        className="icone-igu-inicio" alt="icone-igu"
                                    />
                                </div>
                            </a>
                            <div className="indexPage-incons-desc">Abrir IGU</div>
                        </div>
                    }
                    {hasPermission(role, 'colab') &&
                        <div className="indexPage-icons-boundingBox">
                            <a href="#/" onClick={goToCitiesPage}>
                                <div className="indexPage-main-icon">
                                    <div><FaMapMarkedAlt size={80} /></div>
                                </div>
                            </a>
                            <div className="indexPage-incons-desc">{editCityText}</div>
                        </div>
                    }
                    {role === 'admin' &&
                        <div className="indexPage-icons-boundingBox">
                            <a href="#/" onClick={goToUsersPage}>
                                <div className="indexPage-main-icon">
                                    <div><FaUsers size={80} /></div>
                                </div>
                            </a>
                            <div className="indexPage-incons-desc">Editar Usuários</div>
                        </div>
                    }
                    {role === 'admin' &&
                        <div className="indexPage-icons-boundingBox">
                            <a href="#/" onClick={goToLogsPage}>
                                <div className="indexPage-main-icon">
                                    <div><FaFileImport size={80} /></div>
                                </div>
                            </a>
                            <div className="indexPage-incons-desc">Logs de Acesso</div>
                        </div>
                    }
                    {role === 'user' &&
                        <div className="indexPage-icons-boundingBox">
                            <a href={`/relatorios/${cityID}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <div className="indexPage-main-icon">
                                    <div><FaFileAlt size={88} /></div>
                                </div>
                            </a>
                            <div className="indexPage-incons-desc">Relatórios</div>
                        </div>}
                </div>
            </div>
        </div>
    )
}


export default IndexPage;