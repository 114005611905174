import React, { useEffect, useState, useRef, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas';

import { UserContext } from "../common/contexts/user-context"
import useFetch from '../common/hooks/useFetch';
import CustomTree from '../otherTools/components/_CustomTree';
import Loader from '../common/components/_Loader';

const RelatoriosPage = () => {
    const isComponentMounted = useRef(true);
    const [treeData, setTreeData] = useState([]);
    const { id: cityID } = useParams();
    const { data, loading: loadingPage } = useFetch(`/igu/relatorios/tree/${cityID}`, isComponentMounted, null)

    // load tree data
    useEffect(() => {
        if (data) {
            setTreeData(data);
        };
    }, [data]);
    
    // selected file
    const [fileName, setFileName] = useState(null);
    const [fileSubFolder, setFileSubFolder] = useState(null);
    const [fileFolder, setFileFolder] = useState(null);
    const [fileExt, setFileExt] = useState(null);

    // load file data
    const [fileDataUrl, setFileDataUrl] = useState(null);
    const [loadingFile, setLoadingFile] = useState(false);
    const { token } = useContext(UserContext);
    useEffect(() => {
        if (fileName) {
            const url = fileSubFolder && fileFolder ? 
            `/igu/relatorios/file/${fileFolder}/${fileSubFolder}/${fileName}/${cityID}` :
            fileFolder ? 
            `/igu/relatorios/file/${fileFolder}/${fileName}/${cityID}` :
            `/igu/relatorios/file/${fileName}/${cityID}`;
            // console.log(url)
            setLoadingFile(true);
            fetch(
                process.env.REACT_APP_API_ENDPOINT + url,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                })
                .then(response => response.blob())
                .then(data => { 
                    setFileDataUrl(window.URL.createObjectURL(data));
                    setLoadingFile(false);
                })
        }
    },[fileName, cityID, fileFolder, fileSubFolder, token])

    return loadingPage ? (
        <></>
    ) : (
        <>
            <Offcanvas show={true} backdrop={false}>
                <Offcanvas.Header>
                    <Offcanvas.Title className='docs-offcanvas-title'>
                        Selecione um relatório para exibir
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <CustomTree
                        treeData={treeData}
                        setFileName={setFileName}
                        setFileFolder={setFileFolder}
                        setFileExt={setFileExt}
                        setFileSubFolder={setFileSubFolder}
                    />
                </Offcanvas.Body>
            </Offcanvas>

            {loadingFile && <Loader className={'docs-page-loader'}/>}
            {/* pdf viewer */}
            {fileName && fileExt === "pdf" && 
            <object
                className='my-viewer'
                data={fileDataUrl}
                type="application/pdf"
                aria-labelledby="pdf"
            />}
            {fileName && fileExt !== "pdf" &&
            <img 
                className='my-viewer'
                src={fileDataUrl}
                alt="foto"
            />}
        </>
    )
};

export default RelatoriosPage;