import React, { useEffect, useState, useContext } from "react";
import ReactDomServer from "react-dom/server";
import L from "leaflet";

import IguMapPopup from "./_IguMapPopup";
import { iconBuilding } from "./_IguMapPopupIcon";
import IguToolbar from "../../mainMapToolbar/components/_IguToolbar";
import { createPointLayer } from "../utils/createPointLayer";
import { createGEOJsonLayer } from "../utils/createGJsonLayer";
import { CityContext } from "../../common/contexts/city-context";
import LinhasBusLegend from "./_IguMapLinhasBusLegend";
import SearchModal from "./_SearchModal";
import ODControls from "./_IguODControls";
import enableSmoothScrolling from "../utils/enableSmoothWheelZoom";

const IguMap = (props) => {
    const cityCtx = useContext(CityContext);
    const cityID = cityCtx.cityData._id;
    const userCity = props.cidade;
    const {
        intSemData,
        intNoSemData,
        intMapData,
        viabilidadeSemData,
        contagemVeicData,
        contagemODData,
        contODData,
        projInterligacaoData,
        projSubtrechoData,
        areasSimulacaoData,
        rotasData,
        coordData,
        redeSemData,
        subRedeSemData,
        limiteMunicipioData,
        rotasDesempenhoData,
        linhasOnibusData,
        zoneamentoData,
        matrizODData,
        pontosOnibusData,
        topografiaData,
    } = props.layerData;

    const layerVisibility = {
        layerIntSemVisibility: true,
        layerIntNoSemVisibility: true,
        layerIntMapVisibility: true,
        layerIntAvalVisibility: true,
        layerContVisibility: true,
        layerContODVisibility: true,
        layerODVisibility: true,
        layerProjIntVisibility: true,
        layerProjSubVisibility: true,
        layerAreasSimVisibility: false,
        layerRotasVisibility: true,
        layerCoordVisibility: true,
        layerRedeSemVisibility: true,
        layerSubRedeSemVisibility: true,
        layerRotasDesempenhoVisibility: false,
        layerLinhasOnibusVisibility: false,
        layerZoneamentoVisibility: false,
        layerPontosOnibusVisibility: false,
        layerTopografiaVisibility: false,
    };

    const iguLatitude = +userCity?.coord[0] ?? 0;
    const iguLongitude = +userCity?.coord[1] ?? 0;
    const iguZoom = 16;
    const iguAtribuicao =
        ' &copy <a target="OpenStreetMap contributors" href="https://www.openstreetmap.org/copyright">OpenStreetMap contributors</a>';
    const tileUrl1 = "https://tile.openstreetmap.org/{z}/{x}/{y}.png";
    const tileUrl2 = "http://a.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png";
    // const tileUrl2 = "https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png";

    const [panes, setPanes] = useState({});
    const [b1GroupTools, setB1GroupTools] = useState({});
    const [b2GroupTools, setB2GroupTools] = useState({});

    const legendItems = linhasOnibusData
        .map((feature) => ({
            linha: feature.properties.IGUNOME,
            cor: feature.properties.colorIndex,
            sentido: feature.properties.sentido,
        }))
        .sort((a, b) => (a.linha > b.linha ? 1 : -1));
    const [busLinhasLegendVisible, setBusLinhasLegendVisible] = useState(
        layerVisibility.layerLinhasOnibusVisibility
    );
    const [searchModalVisible, setSearchModalVisible] = useState(false);
    const [iguMap, setIguMap] = useState({});
    const [ODControlsVisible, setODControlsVisible] = useState(false);
    const [zoneamentoLayer, setZoneamentoLayer] = useState([]);

    useEffect(() => {
        // create map
        const tilelayer1 = L.tileLayer(tileUrl1, {
            attribution: iguAtribuicao,
        });
        const tilelayer2 = L.tileLayer(tileUrl2, {
            attribution: iguAtribuicao,
        });
        enableSmoothScrolling(L);
        const myMap = L.map("map", {
            center: [iguLatitude, iguLongitude],
            zoom: iguZoom,
            layers: tilelayer1,
            zoomControl: false,
            scrollWheelZoom: false,
            smoothWheelZoom: true, // enable smooth zoom
            smoothSensitivity: 2, // zoom speed. default is 1
        });
        setIguMap(myMap);

        // barra de escala
        const scale = L.control.scale({ imperial: false });
        scale.addTo(myMap);

        // intLegend
        const intLegend = L.control({ position: "bottomright" });
        intLegend.onAdd = () => {
            var div = L.DomUtil.create("div", "myMap-intLegend"),
                legendItems = [
                    "Intersecção Semaforizada",
                    "Intersecção Mapeada",
                    "Contagem Veicular",
                    "Contagem Origem/Destino",
                ];
            div.innerHTML = '<div class="myMap-intLegend-title">Legenda</div>';
            const legendIcons = [
                "/img/interseccao-icon.svg",
                "/img/stoplights-fill.svg",
                "/img/camera-video-fill.svg",
                "/img/arrow-repeat.svg",
            ];
            for (var i = 0; i < legendItems.length; i++) {
                div.innerHTML +=
                    `<img src="${legendIcons[i]}" alt="bootstrap" width="18" height="18"/> ` +
                    legendItems[i] +
                    (legendItems[i + 1] ? "<br>" : "");
            }
            return div;
        };
        // initial popup
        const myMarker = L.marker([iguLatitude, iguLongitude], {
            icon: iconBuilding,
        }).addTo(myMap);
        const popup = ReactDomServer.renderToString(
            <IguMapPopup city={userCity} brasaoUrl={props.brasaoUrl} />
        );
        myMarker.bindPopup(popup).openPopup();

        // ---------------------------------- //
        // OBS: panes creation order matters! //
        // ---------------------------------- //

        // limite municipio
        myMap.createPane("paneLimiteMunicipio");
        createGEOJsonLayer(
            myMap,
            "paneLimiteMunicipio",
            limiteMunicipioData,
            "Limites",
            cityID
        );

        // zoneamento
        if (zoneamentoData[0]?.features) {
            const paneZoneamento = myMap.createPane("paneZoneamento");
            paneZoneamento.style.display = "none";
            const zoneamento = createGEOJsonLayer(
                myMap,
                "paneZoneamento",
                zoneamentoData[0].features,
                "Zoneamento",
                cityID
            );
            setZoneamentoLayer(zoneamento);
            setPanes((prevState) => ({ ...prevState, paneZoneamento }));
        }

        // topografia
        const paneTopografia = myMap.createPane("paneTopografia");
        paneTopografia.style.display = "";
        createGEOJsonLayer(
            myMap,
            "paneTopografia",
            topografiaData,
            null,
            cityID
        );
        setPanes((prevState) => ({ ...prevState, paneTopografia }));

        // rede semaforica
        const paneRedeSem = myMap.createPane("paneRedeSem");
        paneRedeSem.style.display = "";
        createGEOJsonLayer(myMap, "paneRedeSem", redeSemData, null, cityID);
        setPanes((prevState) => ({ ...prevState, paneRedeSem }));

        // sub rede semaforica
        const paneSubRedeSem = myMap.createPane("paneSubRedeSem");
        paneSubRedeSem.style.display = "";
        createGEOJsonLayer(
            myMap,
            "paneSubRedeSem",
            subRedeSemData,
            null,
            cityID
        );
        setPanes((prevState) => ({ ...prevState, paneSubRedeSem }));

        // proj interligacao layer
        const paneProjInterligacao = myMap.createPane("paneProjInterligacao");
        paneProjInterligacao.style.display = "";
        createGEOJsonLayer(
            myMap,
            "paneProjInterligacao",
            projInterligacaoData,
            null,
            cityID
        );
        setPanes((prevState) => ({ ...prevState, paneProjInterligacao }));

        // proj subtrecho layer
        const paneProjSubtrecho = myMap.createPane("paneProjSubtrecho");
        paneProjSubtrecho.style.display = "";
        const projSubLayer = createGEOJsonLayer(
            myMap,
            "paneProjSubtrecho",
            projSubtrechoData,
            "PoligonoProj",
            cityID
        );
        setPanes((prevState) => ({ ...prevState, paneProjSubtrecho }));

        // area simulacao layer
        const paneAreaSim = myMap.createPane("paneAreaSim");
        paneAreaSim.style.display = "none";
        createGEOJsonLayer(
            myMap,
            "paneAreaSim",
            areasSimulacaoData,
            "PoligonoSim",
            cityID
        );
        setPanes((prevState) => ({ ...prevState, paneAreaSim }));

        // rotas (corredor onibus) layer
        const paneRotas = myMap.createPane("paneRotas");
        paneRotas.style.display = "";
        const rotasLayer = createGEOJsonLayer(
            myMap,
            "paneRotas",
            rotasData,
            "Linhas"
        );
        setPanes((prevState) => ({ ...prevState, paneRotas }));

        // linhas de ônibus layer
        const paneBus = myMap.createPane("paneBus");
        paneBus.style.display = "none";
        createGEOJsonLayer(
            myMap,
            "paneBus",
            linhasOnibusData,
            "LinhasBus",
            cityID
        );
        setPanes((prevState) => ({ ...prevState, paneBus }));

        // rotas desempenho layer
        const paneDesempenho = myMap.createPane("paneDesempenho");
        paneDesempenho.style.display = "none";
        createGEOJsonLayer(
            myMap,
            "paneDesempenho",
            rotasDesempenhoData,
            "Linhas",
            cityID
        );
        setPanes((prevState) => ({ ...prevState, paneDesempenho }));

        // coordenacao layer
        const paneCoord = myMap.createPane("paneCoord");
        paneCoord.style.display = "";
        createGEOJsonLayer(myMap, "paneCoord", coordData, "Linhas", cityID);
        setPanes((prevState) => ({ ...prevState, paneCoord }));

        // interseccao semaforizada layer
        const paneIntSem = myMap.createPane("paneIntSem");
        paneIntSem.style.display = "";
        createPointLayer(
            myMap,
            "paneIntSem",
            intSemData,
            "interseccaoSemMap",
            cityID
        );
        setPanes((prevState) => ({ ...prevState, paneIntSem }));

        // viabilidade semaforica layer
        const paneViabilidadeSem = myMap.createPane("paneViabilidadeSem");
        paneViabilidadeSem.style.display = "";
        createPointLayer(
            myMap,
            "paneViabilidadeSem",
            viabilidadeSemData,
            "viabilidadeSem",
            cityID
        );
        setPanes((prevState) => ({ ...prevState, paneViabilidadeSem }));

        // interseccao nao semaforizada layer
        const paneIntNoSem = myMap.createPane("paneIntNoSem");
        paneIntNoSem.style.display = "";
        createPointLayer(
            myMap,
            "paneIntNoSem",
            intNoSemData,
            "interseccaoSemMap",
            cityID
        );
        setPanes((prevState) => ({ ...prevState, paneIntNoSem }));

        // interseccao mapeada layer
        const paneIntMap = myMap.createPane("paneIntMap");
        paneIntMap.style.display = "";
        createPointLayer(
            myMap,
            "paneIntMap",
            intMapData,
            "interseccaoSemMap",
            cityID
        );
        setPanes((prevState) => ({ ...prevState, paneIntMap }));

        // contagem veic layer
        const paneCont = myMap.createPane("paneCont");
        paneCont.style.display = "";
        createPointLayer(
            myMap,
            "paneCont",
            contagemVeicData,
            "contagemVeic",
            cityID
        );
        setPanes((prevState) => ({ ...prevState, paneCont }));

        // contagem od layer
        const paneContagemOD = myMap.createPane("paneContagemOD");
        paneContagemOD.style.display = "";
        createPointLayer(
            myMap,
            "paneContagemOD",
            contagemODData,
            "contagemOD",
            cityID
        );
        setPanes((prevState) => ({ ...prevState, paneContagemOD }));

        // pontos de onibus layer
        const panePontosOnibus = myMap.createPane("panePontosOnibus");
        panePontosOnibus.style.display = "";
        createPointLayer(
            myMap,
            "panePontosOnibus",
            pontosOnibusData,
            "pontosOnibus",
            cityID
        );
        setPanes((prevState) => ({ ...prevState, panePontosOnibus }));

        // rotatorias/od layer (projetos antigos, RP, Atibaia...)
        const paneContOD = myMap.createPane("paneContOD");
        paneContOD.style.display = "";
        createPointLayer(myMap, "paneContOD", contODData, null, cityID);
        setPanes((prevState) => ({ ...prevState, paneContOD }));

        // B1 group functions
        const setHomeView = () => {
            myMap.setView([iguLatitude, iguLongitude], iguZoom);
        };
        const setExtZoom = () => {
            if (rotasLayer) {
                myMap.fitBounds(rotasLayer.getBounds());
            } else if (projSubLayer) {
                myMap.fitBounds(projSubLayer.getBounds());
            }
        };
        const switchTiles = () => {
            if (myMap.hasLayer(tilelayer1)) {
                myMap.addLayer(tilelayer2);
                myMap.removeLayer(tilelayer1);
            } else {
                myMap.addLayer(tilelayer1);
                myMap.removeLayer(tilelayer2);
            }
        };
        const toggleLegend = () => {
            if (intLegend._map) {
                myMap.removeControl(intLegend);
            } else {
                intLegend.addTo(myMap);
            }
        };

        setB1GroupTools((prevState) => ({
            ...prevState,
            setHomeView,
            setExtZoom,
            switchTiles,
            toggleLegend,
            setSearchModalVisible,
        }));
        setB2GroupTools((prevState) => ({
            ...prevState,
            setBusLinhasLegendVisible,
            setODControlsVisible,
        }));

        return () => myMap.remove();
    }, []);

    return (
        <>
            <div id="map">
                {busLinhasLegendVisible && (
                    <LinhasBusLegend data={legendItems} />
                )}
                <SearchModal
                    show={searchModalVisible}
                    handleClose={() => setSearchModalVisible(false)}
                    intData={intSemData}
                    contData={contagemVeicData}
                    projData={projSubtrechoData}
                    cityID={cityID}
                    map={iguMap}
                />
                {ODControlsVisible && (
                    <ODControls map={iguMap} zoneamento={zoneamentoLayer} />
                )}
            </div>
            <IguToolbar
                cidade={userCity}
                projInfo={props.projInfo}
                b1GroupTools={b1GroupTools}
                b2GroupTools={b2GroupTools}
                panes={panes}
                layerVisibility={{ ...layerVisibility, ODControlsVisible }}
                quantities={{
                    intSem: intSemData.length,
                    intNoSem: intNoSemData.length,
                    intMap: intMapData.length,
                    viabilidadeSem: viabilidadeSemData.length,
                    contVeic: contagemVeicData.length,
                    contagemOD: contagemODData.length,
                    contOD: contODData.length,
                    projInt: projInterligacaoData.length,
                    projSub: projSubtrechoData.length,
                    areasSim: areasSimulacaoData.length,
                    rotas: rotasData.length,
                    coord: coordData.length,
                    redeSem: redeSemData.length,
                    subRedeSem: subRedeSemData.length,
                    rotasDes: rotasDesempenhoData.length,
                    linhasBus: linhasOnibusData.length,
                    odData: matrizODData.length,
                    pontosOnibus: pontosOnibusData.length,
                    topografia: topografiaData.length,
                }}
            ></IguToolbar>
        </>
    );
};

export default IguMap;
