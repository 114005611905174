import L from "leaflet";

const getIconHtml = (feature, layerType, fun = (e) => e) => {
    const featureNum = fun(feature.properties.OINTERSECAONUMERO);
    const featureSituacao = feature.properties.OINTERSECAOSITUACAO;
    const ehOD = feature.properties.BLOCKNAME === "IGU_Rotatoria";
    // const ehContagem = feature.properties.OCONTAGEM === "Sim";
    const iType = layerType ? layerType : "contagem";

    let iDescricao;
    let iLocal;
    let iconHtml;
    let imgSrc, imgColor, imgSize;
    let iconAnchor = [15, 0];
    let popupAnchor = [0, 0];

    // console.log(props.layerType)

    // para interseccoes semaforizadas ou mapeadas
    if (iType === "interseccaoSemMap") {
        // if (
        //     feature.properties.OPROGRAMACAO === "Não" &&
        //     feature.properties.OCROQUI === "Não"
        // ) {
        //     iDescricao = "Mapeada";
        // } else
        if (featureSituacao === "IntersecaoSemaforizada") {
            iDescricao = "Semaforizada";
        } else if (featureSituacao.toLowerCase().includes("principal")) {
            iDescricao = "Principal";
        } else if (featureSituacao.toLowerCase().includes("conjugado")) {
            iDescricao = "Conjugado";
        } else if (featureSituacao.toLowerCase().includes("escopo")) {
            iDescricao = "Mapeada";
        }

        if (iDescricao === "Mapeada") {
            iLocal = "Só Planta";
        } else if (iDescricao === "Semaforizada") {
            iLocal = "Semaforizada";
        } else if (featureSituacao.toLowerCase().includes("corredor")) {
            iLocal = "corredor";
        } else if (featureSituacao.toLowerCase().includes("adjacente")) {
            iLocal = "adjacente";
        } else if (featureSituacao.toLowerCase().includes("nova")) {
            iLocal = "nova";
        } else if (featureSituacao.toLowerCase().includes("adversa")) {
            iLocal = "adversa";
        } else if (featureSituacao.toLowerCase().includes("escopo")) {
            iLocal = "Só Planta";
        } else if (featureSituacao.toLowerCase().includes("naosemaforizada")) {
            iLocal = "esconder";
        } else {
            iLocal = "existente";
        }
        // else {
        //     iLocal = "esconder";
        // }

        switch (iDescricao) {
            case "Principal":
                [imgSrc, imgColor, imgSize] = [
                    "/img/codepen-circle.svg",
                    "white",
                    "15",
                ];
                break;
            case "Conjugado":
                [imgSrc, imgColor, imgSize] = [
                    "/img/link-45deg.svg",
                    "white",
                    "15",
                ];
                break;
            case "Mapeada":
                [imgSrc, imgColor, imgSize] = [
                    "/img/stoplights-fill.svg",
                    "black",
                    "20",
                ];
                break;
            case "Semaforizada":
                [imgSrc, imgColor, imgSize] = [
                    "/img/stoplights-fill.svg",
                    "black",
                    "15",
                ];
                break;
            default:
                [imgSrc, imgColor, imgSize] = [
                    "img/exclamation-triangle.svg",
                    "white",
                    "15",
                ];
                break;
        }

        const htmlInterseccao = `<div class="interseccao-icon interseccao-${iLocal}">
                    ${
                        iDescricao !== "Mapeada"
                            ? `<div>${featureNum}</div>`
                            : ""
                    }
                    <img src="${imgSrc}" alt="bootstrap" width="${imgSize}" height="${imgSize}" fill="${imgColor}"/>
                </div>`;

        iconHtml = htmlInterseccao;

        // para contagens od ou veiculares
    } else if (iType === "pontosOnibus") {
        [imgSrc, imgColor, imgSize] = [
            "/img/signpost-split-fill.svg",
            "white",
            "20",
        ];
        iconHtml = `<div class="pontoOnibus-icon">
            <img src="${imgSrc}" alt="bootstrap" width="${imgSize}" height="${imgSize}" fill="${imgColor}"/>
            </div>`;
    } else {
        if (iType === "contagemVeic") {
            [imgSrc, imgColor, imgSize] = [
                "/img/camera-video-fill.svg",
                "white",
                "15",
            ];
            iconAnchor = [40, 0];
            popupAnchor = [-22, 0];
        } else if (iType === "contagemOD") {
            [imgSrc, imgColor, imgSize] = [
                "/img/arrow-repeat.svg",
                "white",
                "20",
            ];
            iconAnchor = [43, -15];
            popupAnchor = [-26, 0];
        } else if (iType === "viabilidadeSem") {
            [imgSrc, imgColor, imgSize] = [
                "/img/triangle-half.svg",
                "white",
                "15",
            ];
            iconAnchor = [40, 0];
            popupAnchor = [-22, 0];
        } else {
            // igus antigos (RP, Atibaia...)
            if (!ehOD) {
                [imgSrc, imgColor, imgSize] = [
                    "/img/camera-video-fill.svg",
                    "white",
                    "15",
                ];
                iconAnchor = [40, 0];
                popupAnchor = [-22, 0];
            } else if (feature.properties.OCONTAGEMOD === "Sim") {
                [imgSrc, imgColor, imgSize] = [
                    "/img/arrow-repeat.svg",
                    "white",
                    "20",
                ];
            } else {
                [imgSrc, imgColor, imgSize] = [
                    "/img/arrow-repeat.svg",
                    "white",
                    "20",
                ];
            }
        }

        const htmlContagem = `<div class="contagem-icon contagem-${
            ehOD ? "od" : "veicular"
        }">
            ${ehOD ? `<div>${featureNum}</div>` : ""}
            <img src="${imgSrc}" alt="bootstrap" width="${imgSize}" height="${imgSize}" fill="${imgColor}"/>
            </div>`;

        iconHtml = htmlContagem;
    }
    return { iconHtml, iconAnchor, popupAnchor };
};

const getMarkerIcon = (feature, layerType) => {
    const { iconHtml, iconAnchor, popupAnchor } = getIconHtml(
        feature,
        layerType
    );
    const interseccaoIcon = new L.DivIcon({
        iconAnchor: iconAnchor,
        popupAnchor: popupAnchor,
        className: "interseccao",
        html: iconHtml,
    });

    return interseccaoIcon;
};

export { getMarkerIcon, getIconHtml };
