import React, { useState } from "react";
import { BsFillSignpost2Fill, BsBoxArrowInUpRight } from "react-icons/bs";
import { Table } from "react-bootstrap";
import { getIconHtml } from "../utils/getMarkerIcon";
import {
    replaceSearchedText,
    containsSearchText,
} from "../utils/highlightSearchText";

const SearchModalIntersecaoTabBody = ({ layerData, myMap, cityID }) => {
    const sortedData = layerData.sort(
        (a, b) =>
            a.properties.OINTERSECAONUMERO - b.properties.OINTERSECAONUMERO
    );
    const [searchText, setSearchText] = useState("");
    const searchHandler = (e) => {
        setSearchText(e.target.value);
        if (e.target.value) {
            setSearchText(e.target.value);
        }
    };
    const layerTbTitles = [
        <BsFillSignpost2Fill />,
        "Intersecção",
        "Modelo do Controlador",
        "Principal/Conjugado",
        "Rede Semafórica",
        "Projeto por Interligação",
        "Documentação",
    ];

    const layerDataList = sortedData
        .filter(
            (int) =>
                searchText === "" ||
                containsSearchText(
                    int.properties.OINTERSECAONUMERO,
                    searchText
                ) ||
                containsSearchText(
                    int.properties.OINTERSECAOSITUACAO,
                    searchText
                ) ||
                containsSearchText(int.properties.OENDLOCALP, searchText) ||
                containsSearchText(int.properties.OENDLOCALT, searchText) ||
                containsSearchText(int.properties.OENDLOCALS, searchText) ||
                containsSearchText(int.properties.OSUBREDE, searchText) ||
                containsSearchText(int.properties.OINTERLIGACAO, searchText) ||
                containsSearchText(int.properties.OCTRL, searchText)
        )
        .map((int, index) => {
            const { iconHtml } = getIconHtml(int, "interseccaoSemMap");
            const featureSituacao = int.properties.OINTERSECAOSITUACAO;
            const featureNum = int.properties.OINTERSECAONUMERO;
            const logradouro = [
                int.properties.OENDLOCALP,
                int.properties.OENDLOCALS,
                int.properties.OENDLOCALT,
            ]
                .filter((e) => e !== "")
                .flatMap((e, i) => [
                    <span key={2 * i}>
                        {replaceSearchedText(e, searchText)}
                    </span>,
                    <br key={2 * i + 1} />,
                ])
                .slice(0, -1);
            const modelo = replaceSearchedText(
                int.properties.OCTRL,
                searchText
            );
            let iDescricao;
            if (featureSituacao.toLowerCase().includes("principal")) {
                iDescricao = replaceSearchedText("Principal", searchText);
            } else if (featureSituacao.toLowerCase().includes("conjugado")) {
                iDescricao = replaceSearchedText("Conjugado", searchText);
            }
            const rede = replaceSearchedText(
                int.properties.OSUBREDE,
                searchText
            );
            const interligacao = replaceSearchedText(
                int.properties.OINTERLIGACAO,
                searchText
            );

            const setViewToInterseccao = () => {
                myMap.setView(
                    [int.geometry.coordinates[1], int.geometry.coordinates[0]],
                    20
                );
            };

            return (
                <tr
                    className="search-modal-table-content"
                    key={index}
                    onClick={setViewToInterseccao}
                >
                    <td
                        className="search-modal-int-icons"
                        dangerouslySetInnerHTML={{ __html: iconHtml }}
                    />
                    <td className="search-modal-table-align-left ">
                        {logradouro}
                    </td>
                    <td>{modelo}</td>
                    <td>{iDescricao}</td>
                    <td>{rede}</td>
                    <td>
                        <a
                            href={`/docs/projeto/${interligacao}/${cityID}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {interligacao}
                        </a>
                    </td>
                    <td>
                        <a
                            href={`/docs/interseccaoSemMap/${featureNum}/${cityID}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <BsBoxArrowInUpRight />
                        </a>
                    </td>
                </tr>
            );
        });

    return (
        <div
            className="tab-pane fade active show"
            id="int"
            role="tabpanel"
            aria-labelledby="int-tab"
        >
            <input
                type="text"
                className="igu-searchModal-input form-control"
                name="search"
                onChange={searchHandler}
                value={searchText}
                placeholder="Buscar intersecção"
                aria-label="Buscar intersecção"
                aria-describedby="basic-addon2"
            />
            <Table striped hover>
                <thead>
                    <tr className="search-modal-table-header">
                        {layerTbTitles.map((layer, i) => (
                            <th
                                className="search-modal-table-header-names"
                                key={i}
                            >
                                {layer}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>{layerDataList}</tbody>
            </Table>
        </div>
    );
};

export default SearchModalIntersecaoTabBody;
