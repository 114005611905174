import { projectTypes } from '../../mainMap/utils/projectTypes';

const formatTreeData = (obj) => {
    const myObj = {...obj};
    myObj.type = 'directory';
    myObj.files?.forEach((folder) => {
        folder.type = 'directory';
        // tipo de produto
        let siglaProj = folder.name.split("-")[0].substring(1,4)
        folder.extra = folder.name ==="Fotos" ? "" : projectTypes[siglaProj];
        if (folder.files) {
            folder.files.type = 'directory';
            
            folder.files.forEach((file) => {
                siglaProj = file.name.substring(1,4)
                file.extra = file.name ==="Fotos" ? "" : projectTypes[siglaProj];
                // console.log(file.name, siglaProj)

                if (file.files) {
                    file.type = 'directory'
                    // siglaProj = file.name.split("-")[0].substring(1,4)
                    // file.extra = file.name ==="Fotos" ? "" : projectTypes[siglaProj];
                    file.files.forEach(subfile => {
                        subfile.type = 'file'
                    })
                } else {
                    file.type = 'file'
                }
            })
        } else {
            folder.type = 'file';
        }
    })

    return myObj;
}

export { formatTreeData };