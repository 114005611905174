import L from "leaflet";
import ReactDomServer from "react-dom/server";

import { getTooltipText } from "./getTooltipText";
import { getPopupData } from "./getPopupData";
import IguMarkerPopup from "../components/_IguMarkerPopup";
import colorList from "./colorList";

const createGEOJsonLayer = (map, pane, data, style, cityID) => {
    function iguEstiloLinhas(feature) {
        return {
            color: feature.properties.stroke,
            weight: feature.properties.strokeWidth,
            opacity: feature.properties.strokeOpacity,
            dashArray: feature.properties.dashArray,
            fill: false,
            clickable: true,
        };
    }

    function iguEstiloPoligonos(feature) {
        return {
            color: feature.properties.stroke,
            weight: feature.properties.strokeWidth,
            opacity: feature.properties.strokeOpacity,
            dashArray: feature.properties.dashArray,
            fillColor: feature.properties.fill,
            fillOpacity: feature.properties.fillOpacity,
            fill: true,
            clickable: true,
        };
    }
    function iguEstiloPoligonosSimulacao(feature) {
        return {
            color: feature.properties.stroke,
            weight: feature.properties.strokeWidth,
            opacity: feature.properties.strokeOpacity,
            dashArray: feature.properties.dashArray,
            fillColor: feature.properties.fill,
            fillOpacity: feature.properties.fillOpacity,
            fill: true,
            clickable: true,
        };
    }
    function iguEstiloPoligonosProjetos(feature) {
        return {
            color: feature.properties.stroke,
            weight: feature.properties.strokeWidth,
            opacity: feature.properties.strokeOpacity,
            dashArray: feature.properties.dashArray,
            fillColor: feature.properties.fill,
            fillOpacity: feature.properties.fillOpacity,
            fill: true,
            clickable: true,
        };
    }

    function iguEstiloLinhasBus(feature) {
        return {
            color: colorList[feature.properties.colorIndex],
            weight: feature.properties.strokeWidth,
            opacity: feature.properties.strokeOpacity,
            dashArray: feature.properties.dashArray,
            fill: false,
            clickable: true,
            className: `${feature.properties.IGUNOME}-${feature.properties.sentido}-class linha-bus-class`,
        };
    }

    /* -- estilo linha, para o contorno do município */
    function iguEstiloLimites(feature) {
        return {
            color: "#d6acd0",
            weight: 6,
            opacity: 0.8,
            dashArray: "20,15,10,10",
            fillColor: "#d6acd0",
            fillOpacity: 0.3,
            fill: false,
            clickable: false,
        };
    }
    function iguEstiloZoneamento(feature) {
        return {
            color: "rgb(231, 206, 121)",
            dashArray: "10 5 10",
            fillColor: "rgb(231, 206, 121)",
            interactive: false,
        };
    }

    let applyStyle;
    switch (style) {
        case "PoligonoProj":
            applyStyle = iguEstiloPoligonosProjetos;
            break;
        case "PoligonoSim":
            applyStyle = iguEstiloPoligonosSimulacao;
            break;
        case "Linhas":
            applyStyle = iguEstiloLinhas;
            break;
        case "Limites":
            applyStyle = iguEstiloLimites;
            break;
        case "LinhasBus":
            applyStyle = iguEstiloLinhasBus;
            break;
        case "Zoneamento":
            applyStyle = iguEstiloZoneamento;
            break;
        default:
            applyStyle = iguEstiloPoligonos;
            break;
    }

    if (data.length > 0 && data[0].properties) {
        const myLayer = L.geoJSON(data, {
            style: { pane: pane },
            onEachFeature: function (feature, layer) {
                // console.log(feature);
                const popupData = getPopupData(feature);
                const popupContent = ReactDomServer.renderToString(
                    <IguMarkerPopup data={popupData} cityID={cityID} />
                );
                const tooltipProps = feature.properties.Zona
                    ? {
                          permanent: true,
                          direction: "center",
                          className: "zonasLabels zonasLabels-invisible",
                      }
                    : { sticky: true, direction: "right" };

                layer.bindTooltip(getTooltipText(feature), tooltipProps);
                if (popupContent) {
                    layer.bindPopup(popupContent, { minWidth: 260 });
                }
                layer.setStyle(applyStyle(feature));
            },
        }).addTo(map);

        return myLayer;
    }
};

export { createGEOJsonLayer };
