import React, { useState } from "react";

import ButtonGroup from "react-bootstrap/ButtonGroup";
import {
    BsFillSignpost2Fill,
    BsCameraVideoFill,
    BsStickiesFill,
    BsFillCursorFill,
    BsGrid3X3,
    BsStoplightsFill,
    BsReceipt,
    BsArrowRepeat,
    BsBezier2,
    BsIntersect,
    BsDistributeHorizontal,
    BsHddNetworkFill,
    BsShareFill,
    BsShare,
    BsSpeedometer,
    BsTriangleHalf,
    BsFillBarChartFill,
    BsFileEarmarkSlidesFill,
    BsFileEarmarkPlay,
    BsFillSignpostSplitFill,
    BsGlobe,
} from "react-icons/bs";
import { FaBusAlt, FaRoute } from "react-icons/fa";
import { BiNetworkChart } from "react-icons/bi";

import IguToolbarB2 from "./_IguToolbarB2";
import IguInterseccaoIcon from "./_IguInterseccaoIcon";
import { toggleLayer } from "../utils/toogleLayerVisibility";

const ToolbarButtonsG2 = (props) => {
    const panes = props.panes;

    let {
        layerIntSemVisibility,
        layerIntMapVisibility,
        layerViabilidadeSemVisibility,
        layerContVisibility,
        layerContODVisibility,
        layerODVisibility,
        layerProjIntVisibility,
        layerProjSubVisibility,
        layerAreasSimVisibility,
        layerRotasVisibility,
        layerCoordVisibility,
        layerRedeSemVisibility,
        layerSubRedeSemVisibility,
        layerRotasDesempenhoVisibility,
        layerLinhasOnibusVisibility,
        ODControlsVisible,
        layerZoneamentoVisibility,
        layerPontosOnibusVisibility,
        layerTopografiaVisibility,
    } = props.layerVisibility;

    const {
        intSem,
        intMap,
        viabilidadeSem,
        contVeic,
        contagemOD,
        contOD,
        projInt,
        projSub,
        areasSim,
        rotas,
        coord,
        redeSem,
        subRedeSem,
        rotasDes,
        linhasBus,
        odData,
        pontosOnibus,
        topografia,
    } = props.quantities;

    // Viabilidade Semafórica
    const viabilidadeCategories = ["Viabilidade Semafórica"];
    const [viabilidadeSemLayerIsOn, setViabilidadeSemLayerIsOn] = useState(
        layerViabilidadeSemVisibility
    );
    const viabilidadeIcons = [<BsTriangleHalf />];

    // Interseccoes
    const interseccoesCategories = [
        "Intersecção Semaforizada",
        "Intersecção Mapeada",
        "Intersecção em Avaliação",
    ];
    const interseccoesIcons = [
        <IguInterseccaoIcon />,
        <BsStoplightsFill />,
        <BsTriangleHalf />,
    ];
    const [intSemLayerIsOn, setIntSemLayerIsOn] = useState(
        layerIntSemVisibility
    );
    const [intMapLayerIsOn, setIntMapLayerIsOn] = useState(
        layerIntMapVisibility
    );

    // Contagens
    const contagensCategories = [
        "Contagem Veicular",
        "Contagem Origem/Destino",
        "Contagem Origem/Destino",
    ];
    const contagensIcons = [
        <BsCameraVideoFill />,
        <BsArrowRepeat />,
        <BsArrowRepeat />,
    ];
    const [contLayerIsOn, setContLayerIsOn] = useState(layerContVisibility);
    const [odLayerIsOn, setODLayerIsOn] = useState(layerODVisibility); // projetos antigos (RP, Atibaia...)
    const [contODLayerIsOn, setContODLayerIsOn] = useState(
        layerContODVisibility
    );

    // Projetos
    const projetosCategories = [
        "Projeto por Interligação",
        "Projeto por Subtrecho",
        "Topografia",
    ];
    const projetosIcons = [<BsBezier2 />, <BsIntersect />, <BsGlobe />];
    const [projIntLayerIsOn, setProjIntLayerIsOn] = useState(
        layerProjIntVisibility
    );
    const [projSubLayerIsOn, setProjSubLayerIsOn] = useState(
        layerProjSubVisibility
    );
    const [topografiaLayerIsOn, setTopografiaLayerIsOn] = useState(
        layerTopografiaVisibility
    );

    // Simulacoes
    const simulacaoCategories = ["Áreas de Simulação"];
    const simulacaoIcons = [<BsFileEarmarkPlay />];
    const [areasSimLayerIsOn, setareasSimLayerIsOn] = useState(
        layerAreasSimVisibility
    );

    // Rotas
    const rotasCategories = ["Rotas com Desempenho"];
    const rotasIcons = [<BsSpeedometer />];
    const [rotasDesLayerIsOn, setRotasDesLayerIsOn] = useState(
        layerRotasDesempenhoVisibility
    );

    // Redes
    const coordCategories = [
        "Banda de Coordenação",
        "Rede Semafórica",
        "Sub-rede Semafórica",
    ];
    const coordIcons = [<BsHddNetworkFill />, <BsShareFill />, <BsShare />];
    const [coordLayerIsOn, setCoordLayerIsOn] = useState(layerCoordVisibility);
    const [redeSemLayerIsOn, setRedeSemLayerIsOn] = useState(
        layerRedeSemVisibility
    );
    const [subRedeSemLayerIsOn, setSubRedeSemLayerIsOn] = useState(
        layerSubRedeSemVisibility
    );

    // Transporte público
    const tpCategories = [
        "Corredor de ônibus",
        "Linhas de Ônibus",
        "Matriz Origem-Destino",
        "Pontos de Ônibus",
    ];
    const tpIcons = [
        <BsDistributeHorizontal />,
        <FaRoute />,
        <BiNetworkChart />,
        <BsFillSignpostSplitFill />,
    ];
    const [rotasLayerIsOn, setRotasLayerIsOn] = useState(layerRotasVisibility);
    const [linhasBusLayerIsOn, setLinhasBusLayerIsOn] = useState(
        layerLinhasOnibusVisibility
    );
    const [pontosOnibusLayerIsOn, setPontosOnibusLayerIsOn] = useState(
        layerPontosOnibusVisibility
    );
    const { setBusLinhasLegendVisible, setODControlsVisible } =
        props.b2GroupTools;
    const linhasBusOnClick = () => {
        toggleLayer(panes, "paneBus", setLinhasBusLayerIsOn);
        setBusLinhasLegendVisible((prevState) => !prevState);
    };
    const [zoneamentoLayerIsOn, setZoneamentoLayerIsOn] = useState(
        layerZoneamentoVisibility
    );
    const odOnClick = () => {
        // toggle zonasLabels visibility
        document.querySelectorAll(".zonasLabels").forEach((zonaLabel) => {
            zonaLabel.classList.toggle("zonasLabels-invisible");
        });
        setODControlsVisible((prevState) => !prevState);
        toggleLayer(panes, "paneZoneamento", setZoneamentoLayerIsOn);
    };

    return (
        <ButtonGroup aria-label="b1" className="igu-toolbar-group2">
            {/* interseccoes */}
            <IguToolbarB2
                icon={<BsFillSignpost2Fill />}
                description="Intersecções"
                categories={interseccoesCategories}
                categoriesIcons={interseccoesIcons}
                onClick={[
                    () => toggleLayer(panes, "paneIntSem", setIntSemLayerIsOn),
                    () => toggleLayer(panes, "paneIntMap", setIntMapLayerIsOn),
                ]}
                layerVisibility={[intSemLayerIsOn, intMapLayerIsOn]}
                quantities={[intSem, intMap]}
            ></IguToolbarB2>

            {/* contagens */}
            <IguToolbarB2
                icon={<BsCameraVideoFill />}
                description="Contagens"
                categories={contagensCategories}
                categoriesIcons={contagensIcons}
                onClick={[
                    () => toggleLayer(panes, "paneCont", setContLayerIsOn),
                    () =>
                        toggleLayer(
                            panes,
                            "paneContagemOD",
                            setContODLayerIsOn
                        ),
                    () => toggleLayer(panes, "paneContOD", setODLayerIsOn), // projetos antigos (RP, Atibaia...)
                ]}
                layerVisibility={[contLayerIsOn, contODLayerIsOn, odLayerIsOn]}
                quantities={[contVeic, contagemOD, contOD]}
            ></IguToolbarB2>

            {/* projetos */}
            <IguToolbarB2
                icon={<BsStickiesFill />}
                description="Projetos"
                categories={projetosCategories}
                categoriesIcons={projetosIcons}
                onClick={[
                    () =>
                        toggleLayer(
                            panes,
                            "paneProjInterligacao",
                            setProjIntLayerIsOn
                        ),
                    () =>
                        toggleLayer(
                            panes,
                            "paneProjSubtrecho",
                            setProjSubLayerIsOn
                        ),
                    () =>
                        toggleLayer(
                            panes,
                            "paneTopografia",
                            setTopografiaLayerIsOn
                        ),
                ]}
                layerVisibility={[
                    projIntLayerIsOn,
                    projSubLayerIsOn,
                    topografiaLayerIsOn,
                ]}
                quantities={[projInt, projSub, topografia]}
            ></IguToolbarB2>

            {/* simulações */}
            <IguToolbarB2
                icon={<BsFileEarmarkSlidesFill />}
                description="Simulações"
                categories={simulacaoCategories}
                categoriesIcons={simulacaoIcons}
                onClick={[
                    () =>
                        toggleLayer(panes, "paneAreaSim", setareasSimLayerIsOn), // projetos antigos (RP, Atibaia...)
                ]}
                layerVisibility={[areasSimLayerIsOn]}
                quantities={[areasSim]}
            ></IguToolbarB2>

            {/* viablidade sem */}
            <IguToolbarB2
                icon={<BsReceipt />}
                description="Viabilidade"
                categories={viabilidadeCategories}
                categoriesIcons={viabilidadeIcons}
                onClick={[
                    () =>
                        toggleLayer(
                            panes,
                            "paneViabilidadeSem",
                            setViabilidadeSemLayerIsOn
                        ),
                ]}
                layerVisibility={[viabilidadeSemLayerIsOn]}
                quantities={[viabilidadeSem]}
            ></IguToolbarB2>

            {/* rotas */}
            <IguToolbarB2
                icon={<BsFillCursorFill />}
                description="Rotas"
                categories={rotasCategories}
                categoriesIcons={rotasIcons}
                onClick={[
                    () =>
                        toggleLayer(
                            panes,
                            "paneDesempenho",
                            setRotasDesLayerIsOn
                        ),
                ]}
                layerVisibility={[rotasDesLayerIsOn]}
                quantities={[rotasDes]}
            ></IguToolbarB2>

            {/* redes */}
            <IguToolbarB2
                icon={<BsGrid3X3 />}
                description="Redes"
                categories={coordCategories}
                categoriesIcons={coordIcons}
                onClick={[
                    () => toggleLayer(panes, "paneCoord", setCoordLayerIsOn),
                    () =>
                        toggleLayer(panes, "paneRedeSem", setRedeSemLayerIsOn),
                    () =>
                        toggleLayer(
                            panes,
                            "paneSubRedeSem",
                            setSubRedeSemLayerIsOn
                        ),
                ]}
                layerVisibility={[
                    coordLayerIsOn,
                    redeSemLayerIsOn,
                    subRedeSemLayerIsOn,
                ]}
                quantities={[coord, redeSem, subRedeSem]}
            ></IguToolbarB2>

            {/* transporte publico */}
            <IguToolbarB2
                icon={<FaBusAlt />}
                description="Transporte"
                categories={tpCategories}
                categoriesIcons={tpIcons}
                onClick={[
                    () => toggleLayer(panes, "paneRotas", setRotasLayerIsOn),
                    () => linhasBusOnClick(),
                    () => odOnClick(),
                    () =>
                        toggleLayer(
                            panes,
                            "panePontosOnibus",
                            setPontosOnibusLayerIsOn
                        ),
                ]}
                layerVisibility={[
                    rotasLayerIsOn,
                    linhasBusLayerIsOn,
                    ODControlsVisible,
                    pontosOnibusLayerIsOn,
                ]}
                quantities={[
                    rotas,
                    linhasBus,
                    odData ? odData : 0,
                    pontosOnibus,
                ]}
                ehTP={true}
            />
        </ButtonGroup>
    );
};

export default ToolbarButtonsG2;
