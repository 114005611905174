import React, { useRef, useContext } from "react";

import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { GrUserAdmin, GrUserExpert } from "react-icons/gr";
import { BsPower } from 'react-icons/bs'

import useFetch from "../../common/hooks/useFetch";
import { UserContext } from "../../common/contexts/user-context";
import hasPermission from "../../common/utils/acessControl";

const UserProfileWidget = (props) => {
    const isComponentMounted = useRef(true);
    const { token, role, logout } = useContext(UserContext);
    const { data: userData, loading: isUserLoading } = useFetch('/users/me', isComponentMounted, null);
    const cityName = props.cityName;

    const widgetUserIcon = hasPermission(role, 'colab') ? <GrUserAdmin size={20} color="white"/> : <GrUserExpert size={20} />;
    const greeting = `Olá, ${userData?.fullname}`

    const logoutHandler = () => {
        fetch(process.env.REACT_APP_API_ENDPOINT + "/logout", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        }).then(async () => {
            logout();
        })
    }

    return !isUserLoading && (
        <div className="navbar-widget-container">
            <DropdownButton id="navbar-widget-icon-dropdown" title={widgetUserIcon} align="start" variant="secondary">
                <Dropdown.Item className="logout-item" onClick={logoutHandler}>
                    Sair
                    <BsPower color="red" size={17}/>
                </Dropdown.Item>
            </DropdownButton>
            <div className="navbar-widget-text-container">
                <div className="navbar-widget-greeting">
                    {greeting}
                </div>
                <div className="navbar-widget-city-name">
                    {cityName}
                </div>
            </div>
        </div>
    )
}

export default UserProfileWidget;