import React, { useState } from "react";
import { BsBoxArrowInUpRight, BsFillStickiesFill } from "react-icons/bs";
import { Table } from "react-bootstrap";
import {
    replaceSearchedText,
    containsSearchText,
} from "../utils/highlightSearchText";

const SearchModalProjetosTabBody = ({ layerData, myMap, cityID }) => {
    // console.log(layerData);
    const sortedData = layerData.sort(
        (a, b) => a.properties.IGUNOME - b.properties.IGUNOME
    );
    const [searchText, setSearchText] = useState("");
    const searchHandler = (e) => {
        setSearchText(e.target.value);
        // if (e.target.value) {
        //     setSearchText(e.target.value);
        // }
    };
    const layerTbTitles = [<BsFillStickiesFill />, "Projetos", "Documentação"];

    const layerDataList = sortedData
        .filter(
            (proj) =>
                searchText === "" ||
                containsSearchText(proj.properties.IGUNOME, searchText)
        )
        .map((proj, index) => {
            const igunome = replaceSearchedText(
                proj.properties.IGUNOME,
                searchText
            );

            const setViewToProj = () => {
                const invertLatLngFormat = (pol) => {
                    const lngLatArr = [];
                    pol.forEach((element) => {
                        let lat;
                        let long;
                        [lat, long] = element;
                        lngLatArr.push([long, lat]);
                    });
                    return lngLatArr;
                };

                myMap.fitBounds(
                    invertLatLngFormat(proj.geometry.coordinates[0][0])
                );
            };

            return (
                <tr
                    className="search-modal-table-content"
                    key={index}
                    onClick={setViewToProj}
                >
                    <td>{index + 1}</td>
                    <td className="search-modal-table-align-left ">
                        {igunome}
                    </td>
                    <td>
                        <a
                            href={`/docs/projeto/${proj.properties.IGUNOME}/${cityID}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <BsBoxArrowInUpRight />
                        </a>
                    </td>
                </tr>
            );
        });

    return (
        <div
            className="tab-pane fade"
            id="proj"
            role="tabpanel"
            aria-labelledby="proj-tab"
        >
            <input
                type="text"
                className="igu-searchModal-input form-control"
                name="search"
                onChange={searchHandler}
                value={searchText}
                placeholder="Buscar intersecção"
                aria-label="Buscar intersecção"
                aria-describedby="basic-addon2"
            />
            <Table striped hover>
                <thead>
                    <tr className="search-modal-table-header">
                        {layerTbTitles.map((layer, i) => (
                            <th
                                className="search-modal-table-header-names"
                                key={i}
                            >
                                {layer}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>{layerDataList}</tbody>
            </Table>
        </div>
    );
};

export default SearchModalProjetosTabBody;
